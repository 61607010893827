import { useState, useEffect } from "react";
import React from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ExportForm from "../../../shared/components/ExportForm";

export default function ExportModal({
    onExportRegistroHorarios,
    onExportAusencias
}) {
    const [tab, setTab] = useState('registro-horario');

    useEffect(() => {
        
    }, []);

    const handleChangeTab = (e, tab) => setTab(tab);
  
    return (
        <div className="w-100">
            <TabContext value={tab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChangeTab} aria-label="Export Modal">
                    <Tab label="Registro de horarios" value="registro-horario" />
                    <Tab label="Vacaciones" value="vacaciones" />
                </TabList>
                </Box>
                <TabPanel value="registro-horario">
                    <h5 className="mb-3">Exportar registros horarios a Excel</h5>

                    <ExportForm 
                        dateFilter={true}
                        userFilter={true}
                        isUserNullable={false}
                        onSubmitExport={onExportRegistroHorarios}
                    />
                </TabPanel>
                <TabPanel value="vacaciones">
                    <h5 className="mb-3">Exportar vacaciones a Excel</h5>

                    <ExportForm 
                        dateFilter={true}
                        userFilter={true}
                        isUserNullable={false}
                        tipoAusenciaFilter={true}
                        onSubmitExport={onExportAusencias}
                    />
                </TabPanel>
            </TabContext>
        </div>
    )
}