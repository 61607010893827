import moment from 'moment';
import ExcelJS from 'exceljs';

export const createRegistroHorariosExcel = async(
    startDate,
    endDate,
    userId,
    ausencias,
    users,
    fiestasNacionales,
    tipoAusencias
) => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Registros');
    let currDay = moment(startDate);
    let totalDays = moment(endDate).diff(moment(startDate), 'days')+1;

    if(userId === -1) {
        sheet.columns = [
            { header: 'Empleado', key: 'employee', width: 45, style: { alignment: { vertical: 'middle', horizontal: 'center' }}},
            { header: 'Dia', key: 'date', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center' }}},
            { header: 'Hora de entrada', key: 'morning', width: 35, style: { alignment: { vertical: 'middle', horizontal: 'center' }}},
            { header: 'Hora de salida', key: 'afternoon', width: 35, style: { alignment: { vertical: 'middle', horizontal: 'center' }}},
            { header: 'Horas ordinarias', key: 'ordinary', width: 35, style: { alignment: { vertical: 'middle', horizontal: 'center' }}},
            { header: 'Horas complementarias', key: 'complementary', width: 35, style: { alignment: { vertical: 'middle', horizontal: 'center' }}},
        ];

        for(let i = 0; i < users?.length; i++) {
            const startOnDay = users[i].start_on ? moment(users[i].start_on) : null;
            const endOnDay = users[i].end_on ? moment(users[i].end_on) : null;
            let currDay = moment(startDate);
            const schedule = getUserSchedule(users[i]);

            for (let day = 0; day < totalDays; day++) {
                if (day !== 0) {
                    currDay = moment(currDay).add(1, 'day')
                }
                
                if((startOnDay && currDay.isSameOrAfter(startOnDay)) && (!endOnDay || currDay.isSameOrBefore(endOnDay))) {
                    let row = getRegistroHorarioRow(
                        true,
                        currDay,
                        users[i].id, 
                        schedule,
                        fiestasNacionales,
                        ausencias.filter(a => a.created_by === users[i].id)
                    );
    
                    row.employee = users[i].nombre;
                    sheet.addRow(row).commit();
                }
            }
        }
    } else {
        const user = users.find(u => u.id === userId);
        const startOnDay = moment(user.start_on);
        const endOnDay = user.end_on ? moment(user.end_on) : null;
        const schedule = getUserSchedule(user);

        if(schedule.morning?.start && schedule.morning?.end &&
            schedule.afternoon?.start && schedule.afternoon?.end
        ) {
            sheet.columns = [
                { header: 'Dia', key: 'date', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center' }}},
                { header: 'Hora de entrada', key: 'morning', width: 35, style: { alignment: { vertical: 'middle', horizontal: 'center' }}},
                { header: 'Hora de salida', key: 'afternoon', width: 35, style: { alignment: { vertical: 'middle', horizontal: 'center' }}},
                { header: 'Horas ordinarias', key: 'ordinary', width: 35, style: { alignment: { vertical: 'middle', horizontal: 'center' }}},
                { header: 'Horas complementarias', key: 'complementary', width: 35, style: { alignment: { vertical: 'middle', horizontal: 'center' }}},
            ];
        } else {
            if(schedule.morning?.start && schedule.morning?.end) {
                sheet.columns = [
                    { header: 'Dia', key: 'date', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center' }}},
                    { header: 'Horario de mañanas', key: 'morning', width: 35, style: { alignment: { vertical: 'middle', horizontal: 'center' }}},
                    { header: 'Horas ordinarias', key: 'ordinary', width: 35, style: { alignment: { vertical: 'middle', horizontal: 'center' }}},
                    { header: 'Horas complementarias', key: 'complementary', width: 35, style: { alignment: { vertical: 'middle', horizontal: 'center' }}},
                ];
            } 
    
            if(schedule.afternoon?.start && schedule.afternoon?.end) {
                sheet.columns = [
                    { header: 'Dia', key: 'date', width: 25, style: { alignment: { vertical: 'middle', horizontal: 'center' }}},
                    { header: 'Horario de tardes', key: 'afternoon', width: 35, style: { alignment: { vertical: 'middle', horizontal: 'center' }}},
                    { header: 'Horas ordinarias', key: 'ordinary', width: 35, style: { alignment: { vertical: 'middle', horizontal: 'center' }}},
                    { header: 'Horas complementarias', key: 'complementary', width: 35, style: { alignment: { vertical: 'middle', horizontal: 'center' }}},
                ];
            }
        }

        for (let day = 0; day < totalDays; day++) {
            if (day !== 0) {
                currDay = moment(currDay).add(1, 'day')
            }
            
            if(currDay.isSameOrAfter(startOnDay) && (!endOnDay || currDay.isSameOrBefore(endOnDay))) {
                let row = getRegistroHorarioRow(
                    false,
                    currDay,
                    userId,
                    schedule,
                    fiestasNacionales,
                    ausencias
                );

                sheet.addRow(row).commit();
            }
        }
    }

    sheet.getCell('A1').font = { bold: true };
    sheet.getCell('B1').font = { bold: true };
    sheet.getCell('C1').font = { bold: true };
    sheet.getCell('D1').font = { bold: true };
    sheet.getCell('E1').font = { bold: true };
    sheet.getCell('F1').font = { bold: true };

    sheet.eachRow(function(row, rowNumber){
        if(rowNumber > 1) {
            row.eachCell( function(cell, colNumber){
                if(colNumber === 2 || colNumber === 3 || colNumber === 4) {
                    const tipo = tipoAusencias.find(t => t.nombre?.toUpperCase() === cell.text);

                    if(tipo) {
                        row.getCell(colNumber).font = {color: {argb: tipo.color?.replace('#', '')}, bold: true};
                    }
                }
            });
        }
    });

    return await workbook.xlsx.writeBuffer();
}

const getUserSchedule = (user) => {
    const horario = {};
    const currDay = moment();

    if(user.jornada_completa) {
        const morningStart = moment(currDay);
        morningStart.hour(9).minute(0).second(0);
        const morningEnd = moment(currDay);
        morningEnd.hour(14).minute(0).second(0);
        const afternoonStart = moment(currDay);
        afternoonStart.hour(15).minute(0).second(0);
        const afternoonEnd = moment(currDay);
        afternoonEnd.hour(18).minute(0).second(0);

        horario.morning = { start: morningStart, end: morningEnd };
        horario.afternoon = { start: afternoonStart, end: afternoonEnd };
        horario.total = 8;
    } else {
        if(user.media_jornada === 'morning') {
            const horarioUser = user.horario.split('-');
            const morningStartHour = horarioUser[0].split(':');
            const morningEndHour = horarioUser[1].split(':');
            const morningStart = moment(currDay);
            morningStart.hour(parseInt(morningStartHour[0])).minute(parseInt(morningStartHour[1])).second(0);
            const morningEnd = moment(currDay);
            morningEnd.hour(parseInt(morningEndHour[0])).minute(parseInt(morningEndHour[1])).second(0);
            const totalHours = moment.duration(morningEnd.diff(morningStart));

            horario.morning = { start: morningStart, end: morningEnd };
            horario.afternoon = { start: null, end: null };
            horario.total = totalHours.asHours();
        } else {
            const horarioUser = user.horario.split('-');
            const afternoonStartHour = horarioUser[0].split(':');
            const afternoonEndHour = horarioUser[1].split(':');
            const afternoonStart = moment(currDay);
            afternoonStart.hour(parseInt(afternoonStartHour[0])).minute(parseInt(afternoonStartHour[1])).second(0);
            const afternoonEnd = moment(currDay);
            afternoonEnd.hour(parseInt(afternoonEndHour[0])).minute(parseInt(afternoonEndHour[1])).second(0);
            const totalHours = moment.duration(afternoonEnd.diff(afternoonStart));

            horario.morning = { start: null, end: null };
            horario.afternoon = { start: afternoonStart, end: afternoonEnd };
            horario.total = totalHours.asHours();
        }
    }

    return horario;
}

const isHoliday = (date, holidays) => {
    return holidays.find(d => {
        const holidayDay = moment(d);
        let curr; 

        if(d.infinite) {
            const date = moment().format('YYYY') + '-' + (holidayDay.month < 10 ? ('0' + holidayDay.month) : holidayDay.month) + '-' + (holidayDay.day < 10 ? ('0' + holidayDay.day) : holidayDay.day);
            curr = moment(date, "YYYY-MM-DD");
        } else {
            const date = holidayDay.year+ '-' + (holidayDay.month < 10 ? ('0' + holidayDay.month) : holidayDay.month) + '-' + (holidayDay.day < 10 ? ('0' + holidayDay.day) : holidayDay.day);
            curr = moment(date, "YYYY-MM-DD");
        }

        return curr.isSame(date);
    })
}

const isAbsence = (date, userId, holidays, ausencias) => {
    const isUserAbsence = [];
    
    for(let i = 0; i < ausencias.length; i++) {
        const currentDay = moment(date).hour(0)
        const start = moment(ausencias[i].fecha_inicio).hour(0);
        const end = moment(ausencias[i].fecha_fin).hour(0);
        const isSame = currentDay.isBetween(start, end, 'days', '[]');
        const holiday = isHoliday(currentDay, holidays);
  
        if(isSame && currentDay.day() !== 0 && currentDay.day() !== 6 && !holiday && ausencias[i].created_by === userId) {
            isUserAbsence.push(ausencias[i]);
        }
    }

    return isUserAbsence;
}

const getRegistroHorarioRow = (
    isAll,
    currDay,
    userId,
    schedule,
    holidays,
    ausencias
) => {
    const holiday = isHoliday(currDay, holidays);
    const absence = isAbsence(currDay, userId, holidays, ausencias);
    let row;

    if(currDay.day() !== 0 && currDay.day() !== 6 && !holiday && absence?.length === 0) {
        row = {
            date: moment(currDay).format('DD-MM-YYYY'), 
            ordinary: schedule.total,
            complementary: 0
        };

        if(schedule.morning?.start && schedule.morning?.end &&
            schedule.afternoon?.start && schedule.afternoon?.end
        ) {
            row.morning = schedule.morning?.start ? (schedule.morning.start.format('HH:mm:ss') + ' - ' + schedule.morning.end.format('HH:mm:ss')) : '-';
            row.afternoon = schedule.afternoon?.start ? (schedule.afternoon?.start.format('HH:mm:ss') + ' - ' + schedule.afternoon?.end.format('HH:mm:ss')) : '-';
        } else {
            if(schedule.morning?.start && schedule.morning?.end) {
                if(isAll) {
                    row.morning = schedule.morning?.start ? (schedule.morning.start.format('HH:mm:ss')) : '-';
                    row.afternoon = schedule.morning?.end ? schedule.morning.end.format('HH:mm:ss') : '-';
                } else {
                    row.morning = schedule.morning?.start ? (schedule.morning.start.format('HH:mm:ss') + ' - ' + schedule.morning.end.format('HH:mm:ss')) : '-';
                }
            } 
    
            if(schedule.afternoon?.start && schedule.afternoon?.end) {
                if(isAll) {
                    row.morning = schedule.afternoon?.start ? (schedule.afternoon?.start.format('HH:mm:ss')) : '-';
                    row.afternoon = schedule.afternoon?.end ? (schedule.afternoon?.end.format('HH:mm:ss')) : '-';
                } else {
                    row.afternoon = schedule.afternoon?.start ? (schedule.afternoon?.start.format('HH:mm:ss') + ' - ' + schedule.afternoon?.end.format('HH:mm:ss')) : '-';
                }
            }
        }
    } else {
        if(absence.length > 0) {
            row = {
                date: moment(currDay).format('DD-MM-YYYY'), 
                ordinary: 0,
                complementary: 0
            };

            if(schedule.morning?.start && schedule.morning?.end &&
                schedule.afternoon?.start && schedule.afternoon?.end
            ) {
                row.morning = absence[0].tipoAusencia.nombre?.toUpperCase();
                row.afternoon = absence[0].tipoAusencia.nombre?.toUpperCase();
            } else {
                if(schedule.morning?.start && schedule.morning?.end) {
                    row.morning = absence[0].tipoAusencia.nombre?.toUpperCase();
                } 
        
                if(schedule.afternoon?.start && schedule.afternoon?.end) {
                    row.afternoon = absence[0].tipoAusencia.nombre?.toUpperCase();
                }
            }
        } else {
            row = {
                date: moment(currDay).format('DD-MM-YYYY'), 
                ordinary: 0,
                complementary: 0
            };

            if(schedule.morning?.start && schedule.morning?.end &&
                schedule.afternoon?.start && schedule.afternoon?.end
            ) {
                row.morning = '-';
                row.afternoon = '-';
            } else {
                if(schedule.morning?.start && schedule.morning?.end) {
                    row.morning = '-';
                } 
        
                if(schedule.afternoon?.start && schedule.afternoon?.end) {
                    row.afternoon = '-';
                }
            }
        }
    }

    return row;
}