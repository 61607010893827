import moment from 'moment';
import ExcelJS from 'exceljs';

export const createAusenciasExcel = async(
    ausencias,
    tipoAusencias
) => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Ausencias');
    sheet.columns = [
        { header: 'Dia inicio', key: 'start_date', width: 40, style: { alignment: { vertical: 'middle', horizontal: 'center' }}},
        { header: 'Dia fin', key: 'end_date', width: 40, style: { alignment: { vertical: 'middle', horizontal: 'center' }}},
        { header: 'Tipo de ausencia', key: 'type', width: 40, style: { alignment: { vertical: 'middle', horizontal: 'center' }}},
        { header: 'Total días', key: 'total_days', width: 35, style: { alignment: { vertical: 'middle', horizontal: 'center' }}},
        { header: 'Aprobado', key: 'approved', width: 20, style: { alignment: { vertical: 'middle', horizontal: 'center' }}},
        { header: 'Empleado', key: 'employee', width: 55, style: { alignment: { vertical: 'middle', horizontal: 'center' }}},
    ];

    sheet.getCell('A1').font = { bold: true };
    sheet.getCell('B1').font = { bold: true };
    sheet.getCell('C1').font = { bold: true };
    sheet.getCell('D1').font = { bold: true };
    sheet.getCell('E1').font = { bold: true };
    sheet.getCell('F1').font = { bold: true };

    ausencias.forEach(ausencia => {
      sheet.addRow({
        start_date: moment(ausencia.fecha_inicio).format('DD-MM-YYYY'), 
        end_date: moment(ausencia.fecha_fin).format('DD-MM-YYYY'),
        type: ausencia.tipoAusencia.nombre?.toUpperCase(),
        total_days: ausencia.total_dias,
        approved: ausencia.approved ? 'SI' : 'NO',
        employee: ausencia.createdBy.nombre
      }).commit();
    });

    sheet.eachRow(function(row, rowNumber){
      if(rowNumber > 1) {
        row.eachCell( function(cell, colNumber){
          if(colNumber === 3) {
            const tipo = tipoAusencias.find(t => t.nombre?.toUpperCase() === cell.text);
  
            if(tipo) {
              row.getCell(colNumber).font = {color: {argb: tipo.color?.replace('#', '')}, bold: true};
            }
          }
  
          if(colNumber === 5) {
            if(cell.text === 'SI') {
              row.getCell(colNumber).font = {color: {argb: "29a745"}, bold: true};
            } else {
              row.getCell(colNumber).font = {color: {argb: "dc3545"}, bold: true};
            }
          } 
        });
      }
    });

    return await workbook.xlsx.writeBuffer();
}